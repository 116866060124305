import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  IconButton,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  InputLabel,
  FormControl,
  Box,
  Snackbar,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableRow,
  TableCell,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { ArrowBack, Search, CameraAlt } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const BreakfastPage = () => {
  const [searchText, setSearchText] = useState('');
  const [foodItems, setFoodItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [unit, setUnit] = useState('100');
  const [totalCalories, setTotalCalories] = useState(0);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedItem) {
      const baseKcal = selectedItem.kcal;
      const baseUnit = 100;
      setTotalCalories(
        unit === 'Gramm' || unit === 'ml'
          ? Math.round((baseKcal / baseUnit) * quantity)
          : Math.round(baseKcal * quantity)
      );
    }
  }, [quantity, unit, selectedItem]);

  const handleSearch = async (query) => {
    setIsSearching(true);
    setFoodItems([]);
    if (/^\d+$/.test(query)) {
      try {
        const response = await fetch(
          `https://world.openfoodfacts.org/api/v0/product/${query}.json`
        );
        const data = await response.json();
        if (data.product) {
          setFoodItems([
            {
              product_name: data.product.product_name,
              kcal: Math.round(data.product.nutriments['energy-kcal_100g']),
              unit: data.product.product_quantity_unit === 'ml' ? 'ml' : 'g',
            },
          ]);
        } else {
          setSnackbarMessage('Produkt nicht gefunden');
        }
      } catch (error) {
        setSnackbarMessage('Fehler bei der Produktsuche');
      }
    } else {
      window.open(
        `https://world.openfoodfacts.org/cgi/search.pl?search_terms=${query}&search_simple=1&action=process`
      );
    }
    setIsSearching(false);
  };

  return (
    <Container maxWidth="md">
      {/* Header */}
      <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
        <IconButton onClick={() => navigate('/dashboard')} aria-label="back" size="large">
          <ArrowBack />
        </IconButton>
        <Typography variant="h5" textAlign="center">
          Frühstück hinzufügen
        </Typography>
      </Box>

      {/* Suchleiste */}
      <Box mt={2} display="flex" alignItems="center">
        <TextField
          fullWidth
          placeholder="Artikel suchen"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <IconButton onClick={() => handleSearch(searchText)} aria-label="search">
          <Search />
        </IconButton>
        <IconButton aria-label="camera">
          <CameraAlt />
        </IconButton>
      </Box>

      {/* Gefundenes Produkt oder Platzhalter */}
      <Box mt={2}>
        <Card>
          <CardContent>
            {isSearching ? (
              <Typography textAlign="center">
                <CircularProgress size={24} sx={{ mr: 1 }} />
                Produkt wird gesucht...
              </Typography>
            ) : foodItems.length > 0 ? (
              <>
                <Typography>
                  {foodItems[0].product_name} - ({foodItems[0].kcal} kcal / 100
                  {foodItems[0].unit})
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setSelectedItem(foodItems[0]); // Setzt das ausgewählte Produkt
                    setDialogOpen(true); // Öffnet den Dialog
                  }}
                  sx={{ mt: 2 }}
                >
                  Hinzufügen
                </Button>
              </>
            ) : (
              <Typography textAlign="center" color="text.secondary">
                Für die Suche nach einem Produkt die EAN - Nummer eingeben
              </Typography>
            )}
          </CardContent>
        </Card>
      </Box>

      {/* Tabs und Tabelle */}
      <Box mt={2}>
        <Tabs value={tabIndex} onChange={(_, newValue) => setTabIndex(newValue)} centered>
          <Tab label="Letzte Ergebnisse" />
          <Tab label="Eigene Kombinationen" />
        </Tabs>
        <Box mt={2}>
          {tabIndex === 0 && (
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Produkt 1</TableCell>
                  <TableCell>100 kcal</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Produkt 2</TableCell>
                  <TableCell>200 kcal</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
          {tabIndex === 1 && (
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Eigene Kombination 1</TableCell>
                  <TableCell>300 kcal</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Eigene Kombination 2</TableCell>
                  <TableCell>150 kcal</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Box>
      </Box>

      {/* Dialog */}
      <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>{selectedItem?.product_name}</DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="center" gap={2} mt={1}>
            <TextField
              label="Menge"
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(parseInt(e.target.value, 10))}
              fullWidth
            />
            <FormControl fullWidth>
              <InputLabel shrink={true} sx={{ backgroundColor: 'white', px: 1 }}>
                Einheit
              </InputLabel>
              <Select
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                fullWidth
              >
                <MenuItem value="100">100 {selectedItem?.unit}</MenuItem>
                <MenuItem value={selectedItem?.unit}>{selectedItem?.unit}</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Typography mt={2} textAlign="center">
            Gesamtkalorien: {Math.round(totalCalories)} kcal
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button onClick={() => setDialogOpen(false)}>Abbrechen</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              console.log('Hinzufügen gedrückt!');
              setDialogOpen(false);
            }}
          >
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default BreakfastPage;
